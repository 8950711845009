<template>
  <div>
    <event-list :filtered-user="getSelectedUser" />
  </div>
</template>

<script>
import EventList from "@/views/dashboard/pages/Sales/Events/components/EventList.vue";
import { mapGetters } from "vuex";

export default {
  name: "UserBookings",
  components: {
    EventList,
  },
  computed: {
    ...mapGetters({
      getSelectedUser: "user/getSelectedUser",
    }),
  },
};
</script>
